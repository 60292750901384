/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.p-4[data-v-32a3a858] {
  padding: 2rem;
}
.download-link[data-v-32a3a858] {
  display: inline-block;
}
p[data-v-32a3a858] {
  all: revert;
}
.d-flex[data-v-32a3a858] {
  display: flex;
  align-items: center;
}
.fa-bolt[data-v-32a3a858] {
  margin-bottom: 1rem;
  margin-left: 0.5rem;
}
.mt-3[data-v-32a3a858] {
  margin-top: 2rem;
}
[data-v-32a3a858] .wrapper {
  margin-bottom: 1rem;
}
[data-v-32a3a858] .wrapper .theme-icon {
  height: 25px;
  width: 25px;
  align-self: center;
}
[data-v-32a3a858] .wrapper .theme-icon span {
  margin-left: 0 !important;
  color: red !important;
}
[data-v-32a3a858] .action__text {
  margin-left: 5px !important;
  line-height: 20px !important;
}
.performance[data-v-32a3a858] {
  background-color: #f3f3f3;
  min-height: 100%;
}
.performance[data-v-32a3a858]  .selection-holder {
  width: 40rem !important;
}
.performance-wrapper[data-v-32a3a858] {
  position: relative;
  padding: 2rem;
  width: 100%;
  height: 100%;
}
.performance-wrapper .save-slide-out[data-v-32a3a858] {
  width: 85%;
  position: fixed;
  transform: translateY(8rem);
  bottom: 5px;
  transition: transform 0.3s ease-in;
  right: 0;
}
.performance-wrapper .save-slide-out.show-save[data-v-32a3a858] {
  transform: translateY(0rem);
}
.performance-wrapper .save-slide-out .slide-out-inner[data-v-32a3a858] {
  width: 360px;
  margin: 0 auto;
  padding: 1.25rem;
  background: white;
  border-radius: 1rem 1rem 0 0;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.17);
}
.performance-wrapper .save-slide-out .slide-out-inner .btn[data-v-32a3a858]:first-of-type {
  margin-right: 1rem;
}
.success[data-v-32a3a858] {
  color: #238823;
}
.warning[data-v-32a3a858] {
  color: #ffae00;
}
.error[data-v-32a3a858] {
  color: #d2222d;
}
.caps[data-v-32a3a858] {
  text-transform: uppercase;
  font-weight: bold;
}
.bulk-container[data-v-32a3a858] {
  height: 100%;
}
.bulk[data-v-32a3a858] {
  display: grid;
  grid-template-columns: auto 3fr;
  height: 100%;
}
.bulk-operations[data-v-32a3a858] {
  padding-right: 2rem;
}
.bulk-actions[data-v-32a3a858] {
  background-color: white;
}
.save-slide-out[data-v-32a3a858] {
  width: 85%;
  position: fixed;
  transform: translateY(8rem);
  bottom: 10px;
  transition: transform 0.3s ease-in;
  right: 0;
}
.save-slide-out.show-save[data-v-32a3a858] {
  transform: translateY(0rem);
}
.save-slide-out .slide-out-inner[data-v-32a3a858] {
  width: 360px;
  margin: 0 auto;
  padding: 1.25rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.17);
}
.save-slide-out .slide-out-inner .btn[data-v-32a3a858]:first-of-type {
  margin-right: 1rem;
}
.h-100[data-v-32a3a858] {
  height: 100%;
}
.mr-1[data-v-32a3a858] {
  margin-right: 0.5rem;
}
.mt-5[data-v-32a3a858] {
  margin-top: 2.5rem;
}
.btn--discard[data-v-32a3a858] {
  width: auto;
}
.active-operation[data-v-32a3a858] {
  background-color: white;
}
.mb-3[data-v-32a3a858] {
  margin-bottom: 1rem;
}
.upload-warning[data-v-32a3a858] {
  font-weight: bold;
  color: #f05f5f;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#file-upload[data-v-32a3a858] {
  display: none;
}
.bulk-actions__upload[data-v-32a3a858] {
  display: block;
}
.bulk-actions__upload-response[data-v-32a3a858] {
  margin-top: 3rem;
}
.pre[data-v-32a3a858] {
  font-family: monospace;
  white-space: pre-wrap;
  font-size: 1.2rem;
}
.border[data-v-32a3a858] {
  border: 1px solid black;
}
.db-response[data-v-32a3a858] {
  padding: 1rem;
}
.mb-4[data-v-32a3a858] {
  margin-bottom: 2rem;
}
.ml-3[data-v-32a3a858] {
  margin-left: 1rem;
}
.legacy-upload-message[data-v-32a3a858] {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.legacy-upload-message h2[data-v-32a3a858] {
  color: #003061;
  margin-bottom: 1rem;
}
.legacy-upload-message button[data-v-32a3a858] {
  margin-top: 2rem;
}
/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.entity-button[data-v-407b08a4] {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
p[data-v-407b08a4] {
  all: revert;
}
.client-buttons[data-v-407b08a4] {
  display: grid;
  gap: 1rem;
  justify-content: start;
  justify-items: start;
}
table[data-v-407b08a4] {
  margin-top: 1rem;
  width: 100%;
  max-height: 50vh;
}
table td[data-v-407b08a4] {
  padding: 0.5rem;
}
table thead[data-v-407b08a4] {
  position: sticky;
  top: 0;
}
table thead td[data-v-407b08a4] {
  background-color: #003061;
  color: #eee;
}
table tbody[data-v-407b08a4] {
  background-color: #f2f2f2;
}
table tbody tr[data-v-407b08a4]:nth-child(even) {
  background-color: #f3f3f3;
}
.save-slide-out[data-v-407b08a4] {
  width: 85%;
  position: fixed;
  transform: translateY(8rem);
  bottom: 10px;
  transition: transform 0.3s ease-in;
  right: 0;
}
.save-slide-out.show-save[data-v-407b08a4] {
  transform: translateY(0rem);
}
.save-slide-out .slide-out-inner[data-v-407b08a4] {
  width: 360px;
  margin: 0 auto;
  padding: 1.25rem;
  background: white;
  border-radius: 1rem 1rem 0 0;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.17);
}
.save-slide-out .slide-out-inner .btn[data-v-407b08a4]:first-of-type {
  margin-right: 1rem;
}